import { TreatmentFormType } from 'utility/types/treament';
import axios from './axios';

interface TreatmentTypesSearch {
  treatmentIDs: string[];
}
interface TreatmentsSearch {
  treatmentTypeIDs: string[];
}
export const treatmentServices = {
  getTreatmentTypes: (clinicId?: string) =>
    axios.get(`/clinic/${clinicId}/treatments/types`),
  getTreatmentTypesSearch: (clinicId?: string, query?: TreatmentTypesSearch) =>
    axios.get(`/clinic/${clinicId}/treatments/types/search`),
  getTreatments: (clinicId?: string, branchId?: string) =>
    axios.get(`/clinic/${clinicId}/branches/${branchId}/treatments`),
  getTreatmentsSearch: (clinicId?: string, query?: TreatmentsSearch) =>
    axios.get(`/clinic/${clinicId}/treatments/search`),
  getSubTreatment: (
    clinicId?: string,
    branchId?: string,
    treatmentId?: string,
    subTreatmentId?: string
  ) =>
    axios.get(
      `/clinic/${clinicId}/branches/${branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentId}`
    ),
  createSubTreatment: (
    clinicId?: string,
    branchId?: string,
    treatmentId?: string,
    data?: TreatmentFormType
  ) =>
    axios.post(
      `/clinic/${clinicId}/branches/${branchId}/treatments/${treatmentId}/sub-treatments`,
      data
    ),
  updateSubTreatment: (
    clinicId?: string,
    branchId?: string,
    treatmentId?: string,
    subTreatmentId?: string,
    data?: TreatmentFormType
  ) =>
    axios.put(
      `/clinic/${clinicId}/branches/${branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentId}`,
      data
    ),
  deleteSubTreatment: (
    clinicId?: string,
    branchId?: string,
    treatmentId?: string,
    subTreatmentId?: string
  ) =>
    axios.delete(
      `/clinic/${clinicId}/branches/${branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentId}`
    ),
  updateTreatmentStatus: (
    clinicId?: string,
    branchId?: string,
    treatmentId?: string
  ) =>
    axios.patch(
      `/clinic/${clinicId}/branches/${branchId}/treatments/${treatmentId}/status`
    ),
};
